<template>
    <Page />
</template>

<script>
import Page from '../components/BannersPage.vue'

export default {
    components: {
       Page
    },
    data: () => ({
    }),
    methods: {
    },
    mounted() {
    },
}
</script>

