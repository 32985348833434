<template>
<v-container v-if="show" fluid fill-height>
    <v-card :class="`align-self-stretch ` + ($vuetify.breakpoint.mdAndUp ? `pa-4 ` : `pa-0` )" :flat="$vuetify.breakpoint.mdAndUp ? true : false" :elevation="$vuetify.breakpoint.mdAndUp ? `1` : `0`" width="100%">
        <v-card-title class="px-0">
            <h1>Банери</h1>
        </v-card-title>

        <v-data-table @click:row="edit" :search="search" multi-sort :loading="loading" :headers="headers" :items="list" :footer-props="footerProps" mobile-breakpoint="0">
            <template v-slot:top>
                <v-text-field clearable prepend-inner-icon="search" v-model="search" label="Шукати у знайденому"></v-text-field>
            </template>
            <template v-slot:item.img="{ item }">
                <v-img max-width="200" class="rounded-lg my-2" :src="'https://b2b.yugcontract.ua/fileslibrary/banners/'+item.file"></v-img>
            </template>
            <template v-slot:item.name="{ item }">
                <span class="text-uppercase"><b>{{item.name}}</b></span>
            </template>
            <template v-slot:item.title="{ item }">
                <span v-if="item.title">{{item.title}}</span>
                <span v-else>-</span>
            </template>
            <template v-slot:item.subtitle="{ item }">
                <span v-if="item.subtitle">{{item.subtitle}}</span>
                <span v-else>-</span>
            </template>
            <template v-slot:item.text="{ item }">
                <span v-if="item.text">{{item.text}}</span>
                <span v-else>-</span>
            </template>
            <template v-slot:item.btn_text="{ item }">
                <v-btn v-if="item.btn_text" :class="`${item.btn_text_color}--text`" :color="item.btn_color" rounded small>{{item.btn_text}}</v-btn>
            </template>
            <template v-slot:item.is_active="{ item }">
                <v-icon v-if="item.is_active" class="green--text">mdi-check</v-icon>
            </template>
            <template v-slot:item.is_actual="{ item }">
                <v-switch v-model="item.is_actual" dense @click.stop="activeBanner(item)"></v-switch>
            </template>
            <template v-slot:item.delete="{ item }">
                <v-icon :disabled="item.is_tied ?  true : false" class="primary--text" @click.stop="deleteObj(item.id)">mdi-delete</v-icon>
            </template>
        </v-data-table>

        <v-btn @click="newBanner()" color="primary" class="my-6">Додати баннер</v-btn>
        <!-- {{list}} -->

        <v-dialog v-model="editDialog" width="700" :fullscreen="$vuetify.breakpoint.mdAndUp ? false : true">
            <v-system-bar dark>
                <v-spacer></v-spacer>
                <v-icon @click="editDialog=false">mdi-close</v-icon>
            </v-system-bar>
            <v-card tile>
                <v-card-title class="headline">
                    Картка Банера
                </v-card-title>
                <v-card-text class="text-right font-weight-bold">
                    Номер: {{editData.id}}
                </v-card-text>

                <v-card-text>
                    <v-text-field label="Назва *" :error="nameError" outlined v-model="editData.name" dense></v-text-field>
                    <v-subheader class="text-subtitle-2">Банер:</v-subheader>
                    <v-file-input prepend-icon="mdi-camera" accept="image/*" v-model="file" show-size label="Завантажити зображення"></v-file-input>
                    <div v-if="editData.file" class="pb-10 ">
                        <v-img max-width="300" :src="'https://b2b.yugcontract.ua/fileslibrary/banners/'+editData.file"></v-img>
                        <div>{{editData.file}}</div>
                    </div>

                    <v-text-field label="URL" outlined placeholder="https:// або /" v-model="editData.url" dense></v-text-field>

                    <v-text-field label="Колір фону" outlined v-model="editData.bg_color" dense></v-text-field>
                    <v-combobox v-model="editData.block_x" :items="blockAlign" label="Вирівнювання вмісту X" outlined dense></v-combobox>
                    <v-combobox v-model="editData.block_y" :items="blockAlign" label="Вирівнювання вмісту Y" outlined dense></v-combobox>

                    <v-subheader class="text-subtitle-2" dense>Текстовий блок:</v-subheader>
                    <v-text-field label="Заголовок" outlined v-model="editData.title" dense></v-text-field>
                    <v-text-field label="Колір заголовку" outlined v-model="editData.title_color" dense></v-text-field>
                    <v-text-field label="Підзаголовок" outlined v-model="editData.subtitle" dense></v-text-field>
                    <v-text-field label="Колір підзаголовку" outlined v-model="editData.subtitle_color" dense></v-text-field>
                    <v-text-field label="Текст" outlined v-model="editData.note" dense></v-text-field>
                    <v-text-field label="Колір тексту" outlined v-model="editData.note_color" dense></v-text-field>
                    <v-combobox v-model="editData.text_align" :items="textAlign" label="Вирівнювання тексту" outlined dense></v-combobox>

                    <v-subheader class="text-subtitle-2">Кнопка:</v-subheader>
                    <v-text-field label="Колір кнопки" outlined v-model="editData.btn_color" dense></v-text-field>
                    <v-text-field label="Текст" outlined v-model="editData.btn_text" dense></v-text-field>
                    <v-text-field label="Колір тексту" outlined v-model="editData.btn_text_color" dense></v-text-field>

                    <!-- <pre>{{editData}}</pre> -->
                </v-card-text>

                <v-card-actions>
                    <v-btn color="primary" text @click="save()" :loading="loading">
                        Зберегти
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="grey" text @click="editDialog = false">
                        Відміна
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="deleteDialog" width="300" :fullscreen="$vuetify.breakpoint.mdAndUp ? false : true">
            <v-card tile>
                <v-card-title class="headline">
                    Видалити банер?
                </v-card-title>
                <v-card-actions>
                    <v-btn color="primary" text @click="bannerDelete()" :loading="loading">
                        Видалити
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="grey" text @click="deleteDialog = false">
                        Відміна
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-card>
</v-container>
</template>

<script>
import {
    mapActions,
} from 'vuex'
export default {
    components: {},
    props: {},
    data() {
        return {
            show: false,
            search: '',
            nameError: false,
            editDialog: false,
            editData: {},
            file: undefined,
            blockAlign: [
                'start', 'center', 'end'
            ],
            textAlign: ['left', 'center', 'right'],
            deleteDialog: false,
            deleteId: NaN,
            headers: [{
                    text: 'ID',
                    align: 'center',
                    value: 'id',
                    sortable: true,
                    width: '5%',
                },
                {
                    text: 'Зображення',
                    align: 'left',
                    value: 'img',
                    sortable: true,
                    width: '10%',
                },
                {
                    text: 'Назва',
                    align: 'center',
                    value: 'name',
                    sortable: true,
                    width: '20%',
                },
                {
                    text: 'Заголовок',
                    align: 'left',
                    value: 'title',
                    sortable: true,
                    width: '10%',
                },
                {
                    text: 'Підзаголовок',
                    align: 'left',
                    value: 'subtitle',
                    sortable: true,
                    width: '10%',
                },
                {
                    text: 'Текст',
                    align: 'left',
                    value: 'text',
                    sortable: true,
                    width: '20%',
                },
                {
                    text: 'Кнопка',
                    align: 'center',
                    value: 'btn_text',
                    sortable: true,
                    width: '10%',
                },
                {
                    text: 'URL',
                    align: 'left',
                    value: 'url',
                    sortable: true,
                    width: '10%',
                },
                {
                    text: 'Активність',
                    align: 'center',
                    value: 'is_active',
                    sortable: true,
                    width: '5%',
                },
                {
                    text: 'Актуальність',
                    align: 'center',
                    value: 'is_actual',
                    sortable: true,
                    width: '5%',
                },
                {
                    text: '',
                    align: 'center',
                    value: 'delete',
                    sortable: true,
                    width: '5%',
                }
            ],
            footerProps: {
                'items-per-page-options': [20, 40, 80, 100],
                'items-per-page-text': 'на стор.'
            },

        }
    },
    methods: {
        ...mapActions(['touch', 'getBannersList', 'saveBanner', 'setSnackbar','deleteBanner']),
        newBanner() {
            this.file = undefined
            this.editData = {
                "bg_color": "transparent",
                "block_x": "center",
                "block_y": "center",
                "text_align": "center",
            }
            this.editDialog = true
        },
        edit(item) {
            this.file = undefined
            for (let el in item) {
                this.editData[el] = item[el]
            }
            this.editDialog = true
        },
        deleteObj(id){
            this.deleteDialog = true
            this.deleteId = id
        },
        bannerDelete() {
            this.deleteBanner(this.deleteId)
                .then(() => {
                    this.deleteDialog = false
                    this.getBannersList()
                    this.setSnackbar(['success', 'Видалено'])
                })
                .catch(error => {
                    this.setSnackbar(['error', error])
                })

        },
        activeBanner(item) {
            this.saveBanner(item)
                .then(() => {
                    this.setSnackbar(['success', 'Збережено'])
                })
                .catch(error => {
                    this.setSnackbar(['error', error])
                })
        },
        save() {
            this.nameError = false
            if (this.editData.name) {
                this.touch()
                    .then(() => {
                        let formData = new FormData();
                        if (this.file) {
                            formData.append("fileObj", this.file, this.file.name);
                        }
                        for (let key in this.editData) {
                            formData.append(key, this.editData[key] || '')
                        }

                        this.saveBanner(formData)
                            .then(() => {
                                this.editDialog = false
                                this.getBannersList()
                                this.setSnackbar(['success', 'Збережено'])
                            })
                            .catch(error => {
                                this.setSnackbar(['error', error])
                            })

                    })
                    .catch(error => {
                        console.error(error)
                    })
            } else {
                this.nameError = true
                this.setSnackbar(['error', 'Заповніть всі необхідні поля!'])
            }
        }
    },
    watch: {},
    created() {
        if (this.userInfo && (this.userInfo.id == 16896 || this.userInfo.id == 11 || this.userInfo.id == 713)) {
            this.show = true
            this.getBannersList()
        } else {
            this.$router.push('/403')
        }
    },
    computed: {
        userInfo() {
            return this.$store.state.main.userInfo
        },
        list() {
            return this.$store.state.main.bannersList
        },
        loading() {
            return this.$store.state.main.loading
        }
    }
}
</script>

<style>
</style>
